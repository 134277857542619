<template>
  <div>
    <div class="row content-header">
      <div class="content-header-left mb-2 col-md-9 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Hatch Summary
            </h2>
          </div>
        </div>
      </div>
    </div>

    <!-- Table Container Card -->
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col cols="12" md="8">
            <b-row>
              <b-col md="5">
                <label for="example-input">From Date</label>
                <b-form-datepicker
                  id="from-date"
                  placeholder="Choose a date"
                  local="en"
                  v-model="from_date"
                />
              </b-col>
              <b-col md="5">
                <label for="example-input">To Date</label>
                <b-form-datepicker
                  id="to-date"
                  placeholder="Choose a date"
                  local="en"
                  v-model="to_date"
                />
              </b-col>
              <b-col md="2 d-flex align-items-end">
                <b-button @click="getData()" variant="primary ">
                  Filter
                </b-button>
              </b-col>
            </b-row>
          </b-col>
          <!-- <b-col cols="12" md="2">
		   </b-col> -->
          <b-col cols="12" md="4">
            <b-form-group label="Hatch Id">
              <v-select
                v-model="selected_hatch_id"
                :options="hatch_id"
                label="hatch_id"
                :searchable="true"
                :clearable="true"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <div v-if="hatch_summary && hatch_summary.length > 0">
        <b-table
          :items="hatch_summary"
          responsive
          :fields="fields"
          sort-by="id"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
          class="position-relative"
        >
        </b-table>
      </div>
      <div v-else>
        <b-row class="report-view">
          <b-col
            md="12"
            class="d-flex justify-content-center flex-column align-items-center"
          >
            <div class="circle">
              <b-img
                :src="
                  require('@/assets/images/svg/reports/internal-report.svg')
                "
                style="width: 70px; height: 70px"
              />
            </div>
            <h3 class="mt-5 ml-2 textInfo">No Hatch Summary Found</h3></b-col
          >
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BTabs,
  BTab,
  BCardText,
  BFormDatepicker,
  BImg,
  BFormSelect,
  BFormGroup,
} from "bootstrap-vue";

import vSelect from "vue-select";

import { BASE_URL } from "@core/common/constants";
import axiosIns from "@/libs/axios";
import moment from "moment";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BCardText,
    BTabs,
    BTab,
    vSelect,
    BFormDatepicker,
    BFormSelect,
    BFormGroup,
  },
  data() {
    return {
      fromDate: null,
      toDate: null,
      selected: null,
      from_date: null,
      to_date: null,
      hatch_summary: {},
      hatchList: {},
      fields: [
        { key: "source", label: "source" },
        { key: "flock_id", label: "flock id" },
        { key: "batch_id", label: "batch id" },
        {
          key: "set_date",
          label: "set date",
          formatter: (value) => {
            return moment(value).format("MMMM Do YYYY");
          },
        },
        {
          key: "hatch_date",
          label: "hatch date",
          formatter: (value) => {
            return moment(value).format("MMMM Do YYYY");
          },
        },
        { key: "candling_date", label: "candling date" },
        { key: "total_hatchable_egg", label: "total hatchable eggs" },
        { key: "total_eggs_amount", label: "total eggs amount" },
        { key: "total_chicks", label: "total chicks" },
        { key: "saleable_chick", label: "saleable chicks %" },
        { key: "culled_chicks", label: "culls %" },
        { key: "hatch_ability", label: "hatch %" },
        { key: "cost_per_chick", label: "gross production cost/chick" },
        { key: "total_cost_per_chick", label: "total cost/chick" },
      ],
    };
  },
  created: function () {
    this.fromDate = moment().clone().startOf("month").format("YYYY-MM-DD");
    this.toDate = moment().clone().endOf("month").format("YYYY-MM-DD");
    this.getData();
  },

  methods: {
    getData() {
      let url = "";
      const hatchery_id = this.$route.params.farmId;
      if (this.from_date != null || this.to_date != null) {
        url = `web/hatcheries/${hatchery_id}/hatch-summery?from_date=${this.from_date}&to_date=${this.to_date}`;
      } else {
        url = `web/hatcheries/${hatchery_id}/hatch-summery?from_date=${this.fromDate}&to_date=${this.toDate}`;
      }
      axiosIns
        .get(url)
        .then((response) => {
          this.hatch_summary = response.data.hatch_summery_all;
          this.hatch_id = response.data.allHatchId;
        })
        .catch((error) => {
          this.$refs.setErrors(error.response.data.error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.report-view {
  background-color: #f7f7f7;
  border-radius: 5px;
  padding: 2rem 2rem;
  margin: 3rem 3rem;
  height: 400px;
}
.circle {
  width: 195px;
  height: 195px;
  border-radius: 50%;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #296db4;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.textInfo {
  color: rgba(41, 109, 180, 1);
  font-size: 20px;
  font-weight: 600;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
